<template>
  <v-row>
    <Heatmap :templateID="$route.query.templateID" :userID="$route.query.userID"></Heatmap>
  </v-row>
</template>

<script>
  import Heatmap from "@/components/Heatmap"

  export default {
    name: 'HeatmapView',

    components: {
      Heatmap
    }
  }
</script>